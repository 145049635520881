import React from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronUp } from "@styled-icons/boxicons-regular/ChevronUp"
import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown"

function FaqItem({ question, answer }) {
  return (
    <Disclosure>
      {({ open }) => (
        <div className="rounded-xl shadow p-4 bg-white">
          <Disclosure.Button className="flex justify-between w-full px-4 py-2 md:text-lg font-medium text-left text-gray-700 rounded-lg hover:text-pink-900 focus:outline-none focus-visible:ring focus-visible:ring-pink-500 focus-visible:ring-opacity-75">
            <span>{question}</span>
            {open ? (
              <ChevronUp className="h-6" />
            ) : (
              <ChevronDown className="h-6" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500">
            {answer}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

function Faq({ faqs }) {
  const defaultFaqs = [
    {
      question: "Is there a free trial?",
      answer: "Yes, we offer a 1-day free trial for all new users."
    },
    {
      question: "What happens when my trial ends?",
      answer: "After your trial ends, you can choose to subscribe to continue using the service."
    }
  ]

  const faqItems = faqs || defaultFaqs

  return (
    <div className="max-w-3xl p-2 m-4 md:mx-auto rounded-2xl grid gap-6">
      {faqItems.map((faq, index) => (
        <FaqItem
          key={index}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  )
}

export default Faq
