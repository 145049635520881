import React from "react"
import Link from "next/link"

const cleanUrlSegment = segment => {
  return segment
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "")
}

const StyleLinkList = ({
  styles,
  selectedRoom,
  baseUrl = "/interior-design-ai",
  isStartPage = false,
}) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4">
      {styles.map(style => {
        const url =
          isStartPage || !selectedRoom
            ? `${baseUrl}/${cleanUrlSegment(`${style}`)}`
            : `${baseUrl}/${cleanUrlSegment(`${style}-${selectedRoom}`)}`

        return (
          <div key={style} className="font-bold text-lg capitalize">
            <Link href={url}>
              <a title={`${style} ${selectedRoom || "Room"}`}>{style}</a>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default StyleLinkList
