import React from "react"
import FAQ from "./Faq"

const MarketingContent = ({ style, roomName }) => {
  return (
    <>
      <div className="container mx-auto px-4 text-left pb-20">
        <div className="items-center flex flex-wrap-reverse md:flex-wrap">
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
            <img
              src="/assets/ai/upload-and-redesign.jpg"
              alt="Upload and Redesign"
              className="rounded-lg shadow-xl w-full"
            />
          </div>
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
            <div className="md:pr-12">
              <h3 className="font-serif text-3xl font-semibold">
                Transform Your Space with My Room Designer
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">
                Upload a photo of your {roomName.toLowerCase()} and watch it
                transform before your eyes. Get instant redesigns, virtual
                staging, or fresh inspiration.
              </p>
              <ul className="list-none mt-6">
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-600">
                        Effortlessly redesign your {roomName.toLowerCase()} with
                        our AI room design tool.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-600">
                        Explore various styles – from {style} to Modern,
                        Traditional, and more!
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="relative py-20 pb-36 text-left">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap-reverse md:flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <img
                src="/assets/ai/virtual-staging-demo.jpg"
                alt="Virtual Staging Demo"
                className="rounded-lg shadow-xl w-full"
              />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4 flex items-center">
              <div className="md:pr-12">
                <h3 className="font-serif text-3xl font-semibold">
                  Virtual Staging for Real Estate
                </h3>
                <p className="mt-4 mb-8 text-lg leading-relaxed text-gray-600">
                  Showcase your property's potential with our virtual staging
                  feature.
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Transform empty spaces into beautifully furnished
                          rooms.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Impress potential buyers with stunning, realistic
                          visualizations.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mx-auto px-4 text-left pb-20">
        <div className="items-center flex flex-wrap-reverse md:flex-wrap">
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
            <img
              src="/assets/ai/sketch-to-design.jpg"
              alt="Sketch to Design"
              className="rounded-lg shadow-xl w-full"
            />
          </div>
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
            <div className="md:pr-12">
              <h3 className="font-serif text-3xl font-semibold">
                From Sketch to Reality
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">
                Turn your hand-drawn sketches into stunning {roomName.toLowerCase()} designs with our AI technology.
              </p>
              <ul className="list-none mt-6">
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-600">
                        Upload your sketches and watch them come to life as
                        detailed room designs.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-600">
                        Perfect for designers, architects, and DIY enthusiasts
                        alike.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="relative py-20 pb-36 text-left">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap-reverse md:flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <img
                src="/assets/ai/restyle-room-demo.jpg"
                alt="Restyle Room Demo"
                className="rounded-lg shadow-xl w-full"
              />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4 flex items-center">
              <div className="md:pr-12">
                <h3 className="font-serif text-3xl font-semibold">
                  Restyle Your Room
                </h3>
                <p className="mt-4 mb-8 text-lg leading-relaxed text-gray-600">
                  Experiment with different colors, textures, and ideas to
                  transform your space.
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Explore various color palettes and textures for your {roomName.toLowerCase()}.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          Get creative with new design ideas tailored to your
                          space.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mx-auto px-4 text-left pb-20">
        <div className="items-center flex flex-wrap-reverse md:flex-wrap">
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
            <img
              src="/assets/ai/inspiration-to-rooms.jpg"
              alt="Inspiration to Design Variations"
              className="rounded-lg shadow-xl w-full"
            />
          </div>
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
            <div className="md:pr-12">
              <h3 className="font-serif text-3xl font-semibold">
                From Inspiration to Design Variations
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">
                Transform your design inspirations into multiple {style} {roomName.toLowerCase()} concepts with our AI tool.
              </p>
              <ul className="list-none mt-6">
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-600">
                        Generate diverse {style} design variations based on your favorite inspirations.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                    </div>
                    <div>
                      <h4 className="text-gray-600">
                        Explore multiple color schemes, furniture arrangements, and decor options for your {roomName.toLowerCase()}.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <FAQ
        faqs={[
          {
            question: `How does the AI create ${style} ${roomName.toLowerCase()} designs?`,
            answer: `Our AI room design tool uses advanced machine learning algorithms to analyze your uploaded image or sketch. It then generates multiple ${style} design options based on your preferences for style, color, and room type. The AI considers factors like layout, furniture placement, and interior design principles to create realistic and aesthetically pleasing ${roomName.toLowerCase()} concepts.`
          },
          {
            question: `Can I design different types of rooms besides ${roomName.toLowerCase()}s with this AI tool?`,
            answer: `Absolutely! While our AI tool excels at creating ${style} ${roomName.toLowerCase()} designs, it's versatile and can be used for various room types, including living rooms, bedrooms, kitchens, bathrooms, and more. Simply select the desired room type when using the tool, and the AI will tailor its designs accordingly, whether you're looking for ${style} interiors or other popular design styles.`
          },
          {
            question: `How closely will the AI designs match my current ${roomName.toLowerCase()}?`,
            answer: `The AI-generated room designs will incorporate elements from your current ${roomName.toLowerCase()}, but with significant enhancements and style changes. The tool aims to maintain the basic structure and layout of your room while introducing new ${style} design elements, color schemes, and furniture arrangements. This balance ensures that the new concepts feel familiar yet refreshingly different.`
          },
          {
            question: `Will the AI-generated room images have noticeable artifacts?`,
            answer: `We strive to minimize AI artifacts in our generated room designs. Our advanced AI models are trained to produce high-quality, realistic images for all room types, including ${roomName.toLowerCase()}s. While occasional minor artifacts may occur, they are typically not noticeable to the untrained eye. We continuously improve our AI to ensure the highest quality ${style} interior concepts possible.`
          },
          {
            question: `How customizable are the AI-generated ${style} room designs?`,
            answer: `Our AI-generated ${style} room designs, including ${roomName.toLowerCase()}s, are highly customizable. After the initial design is created, you can use our intuitive interface to make adjustments. This includes changing colors, swapping furniture, or tweaking the layout to perfect your interior. You can also generate variations based on a design you like, allowing for endless creativity in your ${style} home decor projects.`
          },
          {
            question: "Can I get a refund for the AI room design service?",
            answer: `Due to the immediate consumption of GPU resources for AI processing when generating room designs, we are unable to offer refunds. The computational costs associated with creating high-quality, ${style} interior concepts for ${roomName.toLowerCase()}s and other rooms are incurred as soon as you use the service. We recommend trying our free demo or starter plan first to ensure the service meets your needs before committing to a full subscription.`
          },
          {
            question: "How do I cancel my subscription to the AI room designer?",
            answer: "You can easily manage and cancel your subscription at any time through your user dashboard. Simply log in to your account, navigate to the subscription management section, and follow the prompts to cancel. Your access to the AI room designer will continue until the end of your current billing period."
          },
          {
            question: "Is my data secure when using the AI room design tool?",
            answer: `We take data security very seriously. All your room designs, including ${roomName.toLowerCase()} designs, uploaded images, and personal information are stored securely using industry-standard encryption methods. We do not share your data with third parties, and your ${style} interior concepts remain completely private. You have full control over your data and can delete it from our servers at any time through your account settings.`
          },
          {
            question: `How can interior designers leverage this AI room design tool?`,
            answer: `Interior designers can use our AI room design tool to quickly generate multiple ${style} concepts for various spaces, including ${roomName.toLowerCase()}s. It's excellent for creating mood boards, exploring different styles rapidly, and presenting various design options to clients. The tool can also help with virtual staging for real estate professionals, allowing them to showcase stylish interiors in empty spaces. This AI-powered approach to interior design can significantly streamline the creative process and enhance client presentations.`
          },
          {
            question: "Can I change my subscription plan for the AI room designer?",
            answer: `Absolutely! We offer flexible subscription options to suit your changing needs. Whether you want to upgrade for more ${style} room designs or downgrade your plan, you can easily modify your subscription at any time. Simply log into your account, click on the 'Billing' option in the top navigation menu, and you'll be directed to our secure payment portal. There, you can review available plans and switch to the one that best fits your current interior design projects, including ${roomName.toLowerCase()} designs. Your new plan will take effect immediately, ensuring you always have the right level of access to our AI room design tools.`
          }
        ]}
      />
    </>
  )
}

export default MarketingContent
